@font-face {
  font-family: 'Lato-Light';
  src: local('Lato-Light'), url(./fonts/Lato-Light.ttf) format('truetype');
}
@media (min-width:1281px){
  svg:not(:root){
    overflow: visible !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.box{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap
}
.material-icons{
  font-size: 2.4em;
  cursor: pointer;
}
.card{
  width: 300px;
}
.card .card-image .card-title{
  color: black;
  font-weight: 400
}
.cart{
  margin-top:40px;
}
.collection .collection-item{
  display: flex;
}
.collection-item.avatar .title {
  font-size: 20px;
  font-weight: 500;
  display: block;
  
}
.collection .collection-item img{
  width: 150px;
  margin-right: 25px;
}
.item-desc{
  align-self:center;
}

.cls-5{stroke:#000;stroke-miterlimit:10;fill:none;stroke-width:5px}

.audioPlayerMobile {
  position: flex !important;
}

.adminLogin {
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  padding-top: 100px;
  width:50%;
}

@media (min-width:1281px){
.rhap_container  {
  position: fixed;
  bottom: 0px;
}
}
.rhap_volume-controls * {
  display: none;
}

.rhap_additional-controls * {
  display: none;
}

.rhap_progress-section * {
  display: none;
}