@font-face {
  font-family: 'Cabin-Regular';
  src: local('Cabin-Regular'), url(./fonts/Cabin-Regular.ttf) format('truetype');
}


.grid-container-8 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.6fr 1.1fr 1.3fr 1fr;
    gap: 1px 1px;
    grid-template-areas:
      ". Logo Logo ."
      ". Options Options ."
      ". Options Options ."
      ". Options Options .";
}

.Logo { grid-area: Logo; width: 80%; }

.Options { 
  font-family: Cabin-Regular;
  grid-area: Options;  
}

.UploadButton { 
  font-family: Cabin-Regular;   
  -webkit-transform:scale(0.5);
  -moz-transform:scale(0.5);
  -ms-transform:scale(0.5);
  transform:scale(0.5);
}
