.grid-container-11 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 0.5fr;
  gap: 1px 1px;
  grid-template-areas:
    ". . . ."
    ". Logo Logo ."
    ". . . .";
}

.Logo { 
  grid-area: Logo;
  align-self: center;
  justify-self: center;
}


.grid-container-101 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". . . ."
    "mobile-logo-area mobile-logo-area mobile-logo-area mobile-logo-area"
    ". . . .";
}
.mobile-logo-area { 
  grid-area: mobile-logo-area;
  margin: auto;
}