
.grid-container-13 {
  display: grid;
  grid-template-columns: 0.9fr 1.1fr 1fr 1fr;
  grid-template-rows: 1.2fr 0.5fr 1.3fr;
  gap: 1px 1px;
  grid-template-areas:
    ". . . ."
    "Product-Info Product-Info Product-Info Remove-Btn"
    ". . . .";
}
.Remove-Btn { grid-area: Remove-Btn; }
.Product-Info { 
  grid-area: Product-Info; 

}