@font-face {
    font-family: 'Lato-Light';
    src: local('Lato-Light'), url(./fonts/Lato-Light.ttf) format('truetype');
}
  

.grid-container-3 {
    display: grid;
    grid-template-columns: 1.2fr 0.9fr 0.9fr 0.9fr 0.9fr 1.2fr;
    grid-template-rows: 0.5fr 1.5fr 0.9fr 1.2fr;
    gap: 1px 1px;
    grid-template-areas: ". Logo Logo Logo Logo ." ". Item-List Item-List Item-List Item-List ." ". Item-List Item-List Item-List Item-List ." ". Checkout-Options Checkout-Options Checkout-Options Checkout-Options .";
}

.Logo { grid-area: Logo;  width: 1203px;  text-align: center; padding-left: 0px;}

.mobile-cart-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "mobile-picture mobile-picture mobile-product-name mobile-product-name"
      "mobile-picture mobile-picture mobile-size mobile-price"
      "mobile-picture mobile-picture mobile-quantity mobile-quantity"
      "mobile-picture mobile-picture mobile-remove mobile-remove";
  }
  
  .mobile-picture { grid-area: mobile-picture; }
  
  .mobile-remove { grid-area: mobile-remove; }
  
  .mobile-product-name { grid-area: mobile-product-name; }
  
  .mobile-size { grid-area: mobile-size; }
  
  .mobile-price { grid-area: mobile-price; }
  
  .mobile-quantity { grid-area: mobile-quantity; }
  

.Item-List { 
    font-family: Lato-Light;
    grid-area: Item-List; 
    padding-bottom: -250px;
}

.Checkout-Options { 
    font-family: Lato-Light;
    grid-area: Checkout-Options; 
    text-align: center; 
    
    -webkit-transform:scale(0.7);
    -moz-transform:scale(0.7);
    -ms-transform:scale(0.7);
    transform:scale(0.7);
}
.container_row {
    height: 50px;
    margin: 0;
    position: relative;
}

.square {
    height: 50px;
    width: 50px;
    background-color: black;
}