@font-face {
    font-family: 'Lato-Light';
    src: local('Lato-Light'), url(./fonts/Lato-Light.ttf) format('truetype');
}

.grid-container-5 {
    display: grid;
    grid-template-columns: 1.2fr 0.9fr 0.9fr 0.9fr 0.9fr 1.2fr;
    grid-template-rows: 0.3fr 1.7fr 0.9fr 1.2fr;
    gap: 1px 1px;
    grid-template-areas:
      ". Logo Logo Logo Logo User-Links"
      ". Product-Information Product-Information Product-Information Product-Information ."
      ". Product-Information Product-Information Product-Information Product-Information ."
      ". . . . . .";
}
  
.Product-Information { 
    font-family: Lato-Light;
    grid-area: Product-Information; 
}

.Logo { 
    grid-area: Logo; 
    padding-top: 40px;
    margin: auto;
}

.User-Links { 
    font-family: Lato-Light;
    grid-area: User-Links; 
    padding-left: 20px;
}

.User-Links-Mobile { 
    font-family: Lato-Light;
    grid-area: User-Links; 
    padding-left: 20px;
    text-align: center;
}

.grid-container-6 {
    display: grid;
    grid-template-columns: 1.1fr 1.1fr 0.3fr 1.4fr 1.3fr;
    grid-template-rows: 0.4fr 0.3fr 2.3fr 0.7fr 1.3fr;
    gap: 1px 1px;
    grid-template-areas:
      "Product-Name Product-Name Product-Name Product-Name Product-Name"
      "Product-Name Product-Name Product-Name Product-Name Product-Name"
      "Product-Preview Product-Preview Product-Information Product-Information Product-Information"
      "Product-Preview Product-Preview Buttons Buttons Buttons"
      "Product-Preview Product-Preview Buttons Buttons Buttons";
}

.container_row {
    height: 50px;
    margin: 0;
    position: relative;
}

.Product-Information { 
    font-family: Lato-Light;
    grid-area: Product-Information; 
}

.Product-Name { 
    font-family: Lato-Light;
    grid-area: Product-Name; 
}

.Product-Preview { grid-area: Product-Preview; }

.Buttons { 

    grid-area: Buttons;
    -webkit-transform:scale(0.7);
    -moz-transform:scale(0.7);
    -ms-transform:scale(0.7);
    transform:scale(0.7);

}

.square {
    height: 50px;
    width: 50px;
    background-color: black;
}


#left-arrow {
    padding-right:25px;
}

#circle {
    padding-right:25px;
}
  
#right-arrow {
}

.Product-Preview-Inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1.7fr 0.3fr;
    gap: 1px 1px;
    grid-template-areas:
      "Photo Photo Photo Photo"
      "Photo Photo Photo Photo"
      "Navi-Bar Navi-Bar Navi-Bar Navi-Bar";
}

.Photo { grid-area: Photo; }

.Navi-Bar { 
    position: absolute;
    height: 1900px;
    width: 650px;
    grid-area: Navi-Bar; 
    display: flex;
    -webkit-transform:scale(0.5);
    -moz-transform:scale(0.5);
    -ms-transform:scale(0.5);
    transform:scale(0.5);
    float: center;
}