.grid-container-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1px 1px;
    grid-template-areas: "Image Name Name . Quantity Price" "Image Size Size . Remove-Btn .";
  }

.Price { grid-area: Price; }

.Quantity { grid-area: Quantity; }

.Remove-Btn { grid-area: Remove-Btn; }

.Image { grid-area: Image; text-align: left; float: left;}

.Name { grid-area: Name; }

.Size { grid-area: Size; }