.grid-container {
    display: grid;
    grid-template-columns: 1.2fr auto auto auto auto 1.2fr;
    grid-template-rows: 1fr 0.9fr 0.9fr 1.2fr;
    gap: 1px 1px;
    grid-template-areas: ". Logo Logo Logo Logo User-Links" "Navigation Category-One Category-Two Category-Three Category-Four ." "Navigation Category-Five Category-Six Category-Seven Category-Eight ." ". . . . . .";
  }
  
.Navigation { 
  grid-area: Navigation; 
  padding-left: 50px;
}

.Category-One { 
  grid-area: Category-One; 
}

.Category-Five { grid-area: Category-Five; }

.Category-Six { grid-area: Category-Six; }

.Category-Seven { grid-area: Category-Seven; }

.Category-Eight { grid-area: Category-Eight; }

.User-Links { grid-area: User-Links; }

.Logo { grid-area: Logo;  }

.Category-Two { grid-area: Category-Two; }

.Category-Three { grid-area: Category-Three; }

.Category-Four { grid-area: Category-Four; }

.store-mobile-header {
  text-align:center;
}

.store-product-container {
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr 1fr; 
  gap: 0px 0px; 
  width: 90%;
  margin: auto;
  grid-template-areas: 
    "mobile-product-one mobile-product-two"
    "mobile-product-three mobile-product-four"
    "mobile-product-five mobile-product-six"
    "mobile-product-seven mobile-product-eight"; 
}

.store-product-container-mobile {
  display: grid; 
  grid-template-columns: auto;
  grid-template-rows: minmax(0,0.78fr) minmax(0,0.78fr) minmax(0,0.78fr) minmax(0,0.78fr);
  gap: 0px 0px; 
  margin: auto;
  grid-template-areas: 
    "mobile-product-one mobile-product-two"
    "mobile-product-three mobile-product-four"
    "mobile-product-five mobile-product-six"
    "mobile-product-seven mobile-product-eight"; 
}
.mobile-product-one { grid-area: mobile-product-one; }
.mobile-product-two { grid-area: mobile-product-two; }
.mobile-product-three { grid-area: mobile-product-three; }
.mobile-product-four { grid-area: mobile-product-four; }
.mobile-product-five { grid-area: mobile-product-five; }
.mobile-product-six { grid-area: mobile-product-six; }
.mobile-product-seven { grid-area: mobile-product-seven; }
.mobile-product-eight { grid-area: mobile-product-eight; }