@font-face {
    font-family: 'Cabin-Regular';
    src: local('Cabin-Regular'), url(./fonts/Cabin-Regular.ttf) format('truetype');
  }
  

.grid-container-2 {
    display: grid;
    grid-template-columns: 1.2fr 0.9fr 0.9fr 0.9fr 0.9fr 1.2fr;
    grid-template-rows: 0.6fr 1.4fr 0.5fr 1.5fr;
    gap: 1px 1px;
    grid-template-areas: ". Logo Logo Logo Logo User-Links" "Navigation Item-One Item-Two Item-Three Item-Four ." "Navigation Item-Five Item-Six Item-Seven Item-Eight ." ". Page-Navigation Page-Navigation Page-Navigation Page-Navigation .";
}
  
.Navigation { 
    font-family: Cabin-Regular;
    grid-area: Navigation; 
}

.Item-One { 
    font-family: Cabin-Regular;
    grid-area: Item-One;
    -webkit-transform:scale(0.7);
    -moz-transform:scale(0.7);
    -ms-transform:scale(0.7);
    transform:scale(0.7);
    height: 310px;
}

.Item-Five { 
    font-family: Cabin-Regular;
    grid-area: Item-Five;
    -webkit-transform:scale(0.7);
    -moz-transform:scale(0.7);
    -ms-transform:scale(0.7);
    transform:scale(0.7);
    height: 310px;
}

.Item-Six { 
    font-family: Cabin-Regular;
    grid-area: Item-Six;
    -webkit-transform:scale(0.7);
    -moz-transform:scale(0.7);
    -ms-transform:scale(0.7);
    transform:scale(0.7);
    height: 310px;
}

.Item-Seven { 
    font-family: Cabin-Regular;
    grid-area: Item-Seven;
    -webkit-transform:scale(0.7);
    -moz-transform:scale(0.7);
    -ms-transform:scale(0.7);
    transform:scale(0.7);
    height: 310px;
}

.Item-Eight { 
    font-family: Cabin-Regular;
    grid-area: Item-Eight;
    -webkit-transform:scale(0.7);
    -moz-transform:scale(0.7);
    -ms-transform:scale(0.7);
    transform:scale(0.7);
    height: 310px;
}

.User-Links { grid-area: User-Links; }

.mobile-container {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 0.5fr 2.1fr 0.4fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "mobile-header mobile-header mobile-header"
      "mobile-products-area mobile-products-area mobile-products-area"
      "mobile-footer mobile-footer mobile-footer"; 
}

.mobile-products-area { grid-area: mobile-products-area; }

.mobile-header { grid-area: mobile-header; padding-top: 35px; }

.mobile-footer { grid-area: mobile-footer; }

.container_row_2 {
    height: 500px;
    position: relative;
    margin: 0 auto;
    padding-left: 35px;
}

.Logo { grid-area: Logo;  width: 1203px;  }

.Item-Two { 
    font-family: Cabin-Regular;
    grid-area: Item-Two;
    -webkit-transform:scale(0.7);
    -moz-transform:scale(0.7);
    -ms-transform:scale(0.7);
    transform:scale(0.7);
    height: 310px;
}

.Item-Three { 
    font-family: Cabin-Regular;
    grid-area: Item-Three;
    -webkit-transform:scale(0.7);
    -moz-transform:scale(0.7);
    -ms-transform:scale(0.7);
    transform:scale(0.7);
    height: 310px;
}

.Item-Four { 
    font-family: Cabin-Regular;
    grid-area: Item-Four;
    -webkit-transform:scale(0.7);
    -moz-transform:scale(0.7);
    -ms-transform:scale(0.7);
    transform:scale(0.7);
    height: 310px;
}

.new-page {
    display: None;
}

.Page-Navigation { 
    grid-area: Page-Navigation; 
    padding-top: 150px;
    text-align: center;

    -webkit-transform:scale(0.2);
    -moz-transform:scale(0.2);
    -ms-transform:scale(0.2);
    transform:scale(0.2);
}

.rotate-translate {
    transform: rotateY(3.142rad);
  }